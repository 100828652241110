// Generated by Framer (be619af)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-Kf0dH"

const variantClassNames = {QHri34wsk: "framer-v-rpf819"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, D4HRcPIxk: text ?? props.D4HRcPIxk ?? "Real Estate"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, D4HRcPIxk, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "QHri34wsk", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rpf819", className, classNames)} data-framer-name={"Tag"} layoutDependency={layoutDependency} layoutId={"QHri34wsk"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-e8dc42fd-fdba-4ae3-b5ee-1d25f6ddbf17, rgb(234, 117, 65))", borderBottomLeftRadius: 1000, borderBottomRightRadius: 1000, borderTopLeftRadius: 1000, borderTopRightRadius: 1000, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1tZWRpdW0=", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.03em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-2d732bb6-c045-4956-8d9d-c11afc219b6d, rgb(252, 251, 244)))"}}>Real Estate</motion.p></React.Fragment>} className={"framer-1qdmms2"} fonts={["FS;Satoshi-medium"]} layoutDependency={layoutDependency} layoutId={"cCinckByw"} style={{"--extracted-r6o4lv": "var(--token-2d732bb6-c045-4956-8d9d-c11afc219b6d, rgb(252, 251, 244))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={D4HRcPIxk} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Kf0dH.framer-smbz4o, .framer-Kf0dH .framer-smbz4o { display: block; }", ".framer-Kf0dH.framer-rpf819 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 10px 18px 10px 18px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-Kf0dH .framer-1qdmms2 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Kf0dH.framer-rpf819 { gap: 0px; } .framer-Kf0dH.framer-rpf819 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Kf0dH.framer-rpf819 > :first-child { margin-left: 0px; } .framer-Kf0dH.framer-rpf819 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 109
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"D4HRcPIxk":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerSZsypCU8y: React.ComponentType<Props> = withCSS(Component, css, "framer-Kf0dH") as typeof Component;
export default FramerSZsypCU8y;

FramerSZsypCU8y.displayName = "Header Tag";

FramerSZsypCU8y.defaultProps = {height: 39, width: 109};

addPropertyControls(FramerSZsypCU8y, {D4HRcPIxk: {defaultValue: "Real Estate", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerSZsypCU8y, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/P2LQKHE6KA6ZP4AAGN72KDWMHH6ZH3TA/ZC32TK2P7FPS5GFTL46EU6KQJA24ZYDB/7AHDUZ4A7LFLVFUIFSARGIWCRQJHISQP.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})